/* You can add global styles to this file, and also import other style files */

@import '~@ng-select/ng-select/themes/default.theme.css';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* Syncfusion */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-buttons/styles/material.css';

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}
