html,
body {
    overflow-x: hidden;
    overflow-y: auto;
}
/* Layout */
.wrapper {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}
.wrapper:before,
.wrapper:after {
    content: ' ';
    display: table;
}
.wrapper:after {
    clear: both;
}

/*
   * Content Wrapper - contains the main content
   */
.content-wrapper {
    -webkit-transition:
        -webkit-transform 0.3s ease-in-out,
        margin 0.3s ease-in-out;
    -moz-transition:
        -moz-transform 0.3s ease-in-out,
        margin 0.3s ease-in-out;
    -o-transition:
        -o-transform 0.3s ease-in-out,
        margin 0.3s ease-in-out;
    transition:
        transform 0.3s ease-in-out,
        margin 0.3s ease-in-out;
    margin-left: 240px;
    z-index: 900;
}

@media (max-width: 767px) {
    .content-wrapper,
    .main-footer {
        margin-left: 0;
    }
}
@media (min-width: 768px) {
    .sidebar-collapse .content-wrapper,
    .sidebar-collapse .main-footer {
        margin-left: 0;
    }
}
@media (max-width: 767px) {
    .sidebar-open .content-wrapper,
    .sidebar-open .main-footer {
        -webkit-transform: translate(240px, 0);
        -ms-transform: translate(240px, 0);
        -o-transform: translate(240px, 0);
        transform: translate(240px, 0);
    }
}
.content-wrapper {
    min-height: 100vh;
    background-color: #f3f4f9;
    z-index: 900;
    padding: 32px 10px 5px 27px;
    position: relative;
}

.hold-transition .content-wrapper,
.hold-transition .right-side,
.hold-transition .main-footer,
.hold-transition .main-sidebar,
.hold-transition .left-side,
.hold-transition .main-header .navbar,
.hold-transition .main-header .logo,
.hold-transition .menu-open .fa-angle-left {
    /* Fix for IE */
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}
/* Content */
.content {
    min-height: 45px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 18px;
}

/* General Links */
a {
    color: #3c8dbc;
    cursor: pointer;
}
a:hover,
a:active,
a:focus {
    outline: none;
    text-decoration: none;
    color: #72afd2;
}

/*
   * Component: Main sidebar
   * ----------------------
   */
.main-sidebar .logo .logo-lg {
    display: block;
    padding-left: 12px;
    padding-bottom: 20px;
}
.main-sidebar .logo .logo-mini {
    display: none;
}
.main-header .navbar-brand {
    color: #fff;
}
.content-header {
    position: relative;
    padding-right: 18px;
    margin-top: -18px;
}
.logo-lg img {
    width: 100px;
}

@media (max-width: 767px) {
    .main-header {
        position: relative;
    }
    .main-header .logo,
    .main-header .navbar {
        width: 100%;
        float: none;
    }
    .main-header .navbar {
        margin: 0;
    }
    .main-header .navbar-custom-menu {
        float: right;
    }
}
@media (max-width: 991px) {
    .navbar-collapse.pull-left {
        float: none !important;
    }
    .navbar-collapse.pull-left + .navbar-custom-menu {
        display: block;
        position: absolute;
        top: 0;
        right: 40px;
    }
}

.box-outline {
    margin-top: 25px;
    border-radius: 24px;
    border: solid 1px #cfd3f0;
    background-color: #ffffff;
    width: 336px;
    height: 40px;
}

.submit-btn {
    border: none;
    background: linear-gradient(90deg, #ee8031 0%, #e45248 100%);
    box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    outline: none;
    color: #ffffff;
    font-size: 15px;
}

/*
   * Component: Sidebar
   * ------------------
   */
.icontoggle {
    float: left;
    margin-left: -36px;
    margin-top: 5px;
    z-index: 900;
    position: fixed;
}

@media (min-width: 768px) {
    .sidebar-mini.sidebar-collapse .icontoggle {
        transform: rotate(180deg);
        margin-top: 10px;
    }
}

.main-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    padding-top: 36px;
    min-height: 100%;
    width: 240px;
    z-index: 810;
    -webkit-transition:
        -webkit-transform 0.3s ease-in-out,
        width 0.3s ease-in-out;
    -moz-transition:
        -moz-transform 0.3s ease-in-out,
        width 0.3s ease-in-out;
    -o-transition:
        -o-transform 0.3s ease-in-out,
        width 0.3s ease-in-out;
    transition:
        transform 0.3s ease-in-out,
        width 0.3s ease-in-out;
    background: #43073d;
}

.main-sidebar::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.main-sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.main-sidebar::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

@media (max-width: 767px) {
    .main-sidebar {
        padding-top: 100px;
    }
}

@media (max-width: 767px) {
    .main-sidebar {
        -webkit-transform: translate(-240px, 0);
        -ms-transform: translate(-240px, 0);
        -o-transform: translate(-240px, 0);
        transform: translate(-240px, 0);
    }
}
@media (min-width: 768px) {
    .sidebar-collapse .main-sidebar {
        -webkit-transform: translate(-240px, 0);
        -ms-transform: translate(-240px, 0);
        -o-transform: translate(-240px, 0);
        transform: translate(-240px, 0);
    }
}
@media (max-width: 767px) {
    .sidebar-open .main-sidebar {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}
.sidebar {
    padding-bottom: 10px;
}

.sidebar-footer {
    position: relative;
    bottom: 14px;
    display: inline;
    margin: 0 25px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #9c829d;
    font-family: Lato;
    font-size: 14px;
}

.sidebar-menu-footer {
    padding-bottom: 25px;
}

@media (min-width: 768px) {
    .sidebar-mini.sidebar-collapse .sidebar-menu-footer > a > img {
        display: inherit;
        margin-left: -80px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu-footer > a > span {
        display: none;
    }
}
.sidebar-menu-footer > a > img {
    padding-left: 17px;
    padding-right: 17px;
    display: none;
}

.sidebar-menu-footer > a > span {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    text-align: center;
    color: #ffffff;
}

.sidebar-footer-copyright {
    font-family: Lato;
    font-size: 11px;
    letter-spacing: 0.2px;
    color: #9c829d;
    text-align: center;
}

.sidebar-footer-compressed {
    width: 155px;
    height: 13px;
    font-family: Lato;
    font-size: 11px;
    letter-spacing: 0.2px;
    color: #9c829d;
    margin-left: -80px;
    display: none;
}
@media (min-width: 768px) {
    .sidebar-mini.sidebar-collapse .sidebar-footer-compressed {
        display: block;
    }
    .sidebar-mini.sidebar-collapse .sidebar-footer-copyright {
        display: none;
    }
}

.sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
.sidebar-menu > li {
    position: relative;
    margin: 0;
    padding: 0;
}
.sidebar-menu > li > a {
    padding: 19px 5px 20px 17px;
    display: block;
}

.sidebar-menu > li:active a {
    padding: 19px 5px 20px 15px;
}

.treeview:hover {
    background: #62275c;
}
.menu-open li a {
    background: #62275c;
}

/********************************************************/

.sidebar-menu li:active {
    background: #62275c;
    border-left: 2px solid #ffffff;
}

.active-sidebar {
    background: #62275c;
    border-left: 2px solid #ffffff;
}

.active-sidebar-anchor {
    padding: 19px 5px 20px 15px !important;
    color: #ffffff !important;
}

.sidebar-menu .active > .treeview-menu {
    display: block;
}
.sidebar-menu a {
    color: #fff;
}
.sidebar-menu span {
    padding-left: 10px;
}

.sidebar-menu > li a span {
    height: 17px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    width: 91px;
    padding-left: 17px;
}
/************************************************************************/
/*
   * Component: Sidebar Mini
   */
@media (min-width: 768px) {
    .sidebar-mini.sidebar-collapse .content-wrapper,
    .sidebar-mini.sidebar-collapse .right-side,
    .sidebar-mini.sidebar-collapse .main-footer {
        margin-left: 60px !important;
        z-index: 900;
    }
    .sidebar-mini.sidebar-collapse .main-sidebar {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
        width: 60px !important;
        z-index: 850;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li {
        position: relative;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
        margin-right: 0;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
        border-top-right-radius: 4px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
        border-bottom-right-radius: 4px;
    }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom-right-radius: 4px;
    }
    .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,
    .sidebar-mini.sidebar-collapse .sidebar-form,
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,
    .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
        display: none !important;
        -webkit-transform: translateZ(0);
    }
    .sidebar-mini.sidebar-collapse .logo {
        width: 50px;
    }
    .sidebar-mini.sidebar-collapse .logo > .logo-mini {
        display: block;
        margin-left: 10px;
        margin-right: -15px;
        font-size: 18px;
        padding-bottom: 20px;
    }
    .sidebar-mini.sidebar-collapse .logo > .logo-lg {
        display: none;
    }
    .sidebar-mini.sidebar-collapse .navbar {
        margin-left: 50px;
    }
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
    .sidebar-menu
    > li:hover
    > a
    > span:not(.pull-right),
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
    .sidebar-menu
    > li:hover
    > .treeview-menu {
    display: block !important;
    position: absolute;
    width: 180px;
    left: 50px;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
    .sidebar-menu
    > li:hover
    > a
    > span {
    top: 0;
    margin-left: -3px;
    padding: 12px 5px 12px 20px;
    background-color: inherit;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
    .sidebar-menu
    > li:hover
    > a
    > .pull-right-container {
    position: relative !important;
    float: right;
    width: auto !important;
    left: 180px !important;
    top: -22px !important;
    z-index: 900;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
    .sidebar-menu
    > li:hover
    > a
    > .pull-right-container
    > .label:not(:first-of-type) {
    display: none;
}
.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse
    .sidebar-menu
    > li:hover
    > .treeview-menu {
    top: 44px;
    margin-left: 0;
}
.sidebar-expanded-on-hover .main-footer,
.sidebar-expanded-on-hover .content-wrapper {
    margin-left: 50px;
}
.sidebar-expanded-on-hover .main-sidebar {
    box-shadow: 3px 0 8px rgba(0, 0, 0, 0.125);
}
.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
    white-space: nowrap;
    overflow: hidden;
}
.sidebar-menu:hover {
    overflow: visible;
}
.sidebar-form,
.sidebar-menu > li.header {
    overflow: hidden;
    text-overflow: clip;
}
.sidebar-menu li > a {
    position: relative;
    color: #9c829d;
    text-decoration: auto;
}

.sidebar-menu li > a:hover {
    color: #ee8031;
}

.sidebar-menu li > a:active {
    color: #ffffff;
}

.sidebar-menu li > a > .pull-right-container {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -7px;
}

/*
   * Component: Nav
   * --------------
   */
.nav > li > a:hover,
.nav > li > a:active,
.nav > li > a:focus {
    color: #444;
    background: #f7f7f7;
}

/************************ placeholder ***********************************************/

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #43073d;
}
::-moz-placeholder {
    /* Firefox 19+ */
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #43073d;
}
:-ms-input-placeholder {
    /* IE 10+ */
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #43073d;
}
:-moz-placeholder {
    /* Firefox 18- */
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #43073d;
}

/************************ nav router tabination ***********************************************/

.nav > li > a {
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.27px;
    color: #43073d;
}

.nav-link {
    display: block;
    padding: 0 0 0 0;
    margin-right: 32px;
    padding-bottom: 19px;
}

.nav-tabs .nav-link {
    border: none;
}

.nav-tabs .nav-link.active {
    background-color: inherit;
    font-weight: bold;
    color: #ee8031;
    border-bottom: 2px solid #ee8031;
}

.nav-tabs .nav-link:hover {
    background-color: inherit;
    color: #ee8031;
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

/******************* nav inline tabination ***************************/

.nav-tabs {
    padding: 0 0 0 0;
    border-bottom: 1px solid #ddd;
    color: #43073d;
    background: inherit;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.27px;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    border-width: 0;
}
.nav-tabs > li > a {
    border: none;
    padding-bottom: 19px;
}
.nav-tabs > li > a.active {
    border: none;
    background: inherit;
    font-weight: bold;
    color: #ee8031 !important;
    border-bottom: 2px solid #ee8031 !important;
}
.nav-tabs > li > a:hover {
    border: none;
    color: #ee8031 !important;
    background: inherit;
}
.nav-tabs > li > a::after {
    content: '';
    background: inherit;
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: -1px;
    transition: all 250ms ease 0s;
    transform: scale(0);
}

.tab-pane {
    padding: 0 0;
}
.tab-content {
    padding: 0;
}
.nav-tabs > li {
    text-align: left;
}

/*************************************************************
*                   table
**************************************************************/
.custom-table {
    margin-top: -25px;
}

.custom-table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 2px;
}

.custom-table tbody {
    display: block;
    height: calc(100vh - 241px);
    overflow: auto;
}

.custom-table.grv-print tbody {
    display: block;
    height: auto;
    overflow: auto;
}

.custom-table tbody > tr {
    display: table;
    width: 99%;
    table-layout: fixed;
    height: 56px;
    background: #ffffff;
    margin: 10px 11px 10px 0px;
    line-height: 40px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #504a5a;
    border: 6px solid #ffffff;
    border-radius: 6px;
}

.custom-table.grv-print tbody > tr {
    font-size: 24px;
    color: #000;
    word-wrap: break-word;
    line-height: 25px;
}

.custom-table.grv-print tbody > tr:nth-child(odd) {
    background-color: #f2f2f2;
}

.custom-table tbody > tr a {
    color: #3c8dbc; /*#504a5a;*/
}

.custom-table thead tr {
    display: table;
    width: 99%;
    table-layout: fixed;
    margin: 18px 0 10px 0;
    padding: 0 11px 0 0px;
}

.custom-table thead tr > th {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    color: #9c829d;
    /* padding: 0 8px 0 8px !important; */
}

.custom-table.grv-print thead tr > th {
    font-size: 18px;
    color: #000;
    font-weight: bold;
}

.custom-table thead tr > th:hover {
    color: #9c829d;
}

.custom-table tfoot tr {
    display: table;
    width: calc(100% - 11px);
    table-layout: fixed;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    color: #9c829d;
    padding: 10px 0 10px 0;
    /* margin: 0 11px 0 0; */
}

.custom-table tfoot tr td span {
    color: #43073d;
    font-weight: bold;
    padding: 0 5px;
}

.custom-table td {
    white-space: normal;
    /* padding: 0 10px 0 0; */
}

.custom-table.grv-print td {
    white-space: unset;
    word-wrap: break-word;
    padding: 0 10px 0 0;
}

.custom-table th {
    white-space: nowrap;
    padding: 0 6px 0 5px;
}

.custom-table.custom-last-row table tbody td:last-child {
    width: 6%;
    padding-right: 0px;
    padding-left: 2%;
}

.custom-table.custom-last-row table thead th:last-child {
    width: 6%;
    padding-right: 12px;
}

.custom-table table tfoot td:last-child {
    text-align: right;
}

.custom-table tbody::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.custom-table tbody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.custom-table tbody::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

.custom-table input {
    width: 100%;
    height: 32px;
    background-color: #dee0ea;
}

.custom-table .form-table-row {
    background: #f3f4f9 !important;
    border: none !important;
}

.custom-table .form-table-row input {
    height: inherit !important;
    outline: none;
    border-radius: 6px;
    border: none;
}

.custom-table .form-table-row input:focus {
    height: inherit !important;
    outline: none;
    border-radius: 6px;
    border: solid 2px #ee8031;
}

.custom-table .form-table-row td {
    padding: 0 8px 0 8px !important;
    background: #fff;
}

.custom-table .form-table-row td.scroll-td {
    overflow-x: scroll;
}

.custom-table .form-table-row td::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
.custom-table .form-table-row td::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.custom-table .form-table-row td::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

.custom-table.default-width .form-table-row td:first-child {
    width: 22.5%;
}

.custom-table.default-width .form-table-row th:first-child {
    width: 22.5%;
}

.custom-table.default-width .form-table-row td:nth-child(2) {
    width: 22.5%;
}

.custom-table.default-width .form-table-row th:nth-child(2) {
    width: 22.5%;
}

.custom-table.default-width .form-table-row td:last-child {
    width: 5%;
    padding-left: 1.5% !important;
}

.custom-table.default-width .form-table-row th:last-child {
    width: 5%;
}

/* SMART SAFE CUSTOM TABLE */

.custom-table.smart-safes .form-table-row table {
    border-collapse: collapse;
}

.custom-table.smart-safes .form-table-row.view-form td {
    background: none;
}

.custom-table.smart-safes .form-table-row.view-form {
    background: #ffffff !important;
}
.custom-table.smart-safes .form-table-row.view-form:hover {
    background: #d3d3d3 !important;
}

.custom-table.smart-safes.configuration .form-table-row.view-form.table-row-selected {
    background: #d3d3d3 !important;
}

.custom-table.smart-safes .form-table-row.view-form .status-div {
    background-color: inherit !important;
}

.custom-table.smart-safes .form-table-row td > a {
    color: #43073d;
    background-color: transparent;
}

.custom-table.smart-safes .form-table-row td > a:hover {
    color: #43073d;
    background-color: transparent;
    text-decoration: underline;
}

.custom-table.smart-safes tbody > tr {
    line-height: 25px;
    letter-spacing: 0.15px;
}

.custom-table.smart-safes .form-table-row .circle-left {
    margin: 5px 5px 5px 2px;
}

.custom-table.smart-safes th {
    padding: 0 8px 0 12px !important;
}

.custom-table.smart-safes .form-table-row th:first-child {
    width: 20%;
    vertical-align: middle;
    white-space: initial;
    text-align: left;
}

.custom-table.smart-safes .form-table-row td:first-child {
    text-align: left;
    width: 20%;
    white-space: initial;
}

.custom-table.smart-safes .form-table-row th:nth-child(2) {
    vertical-align: middle;
    width: 15%;
    white-space: initial;
    text-align: left;
}

.custom-table.smart-safes .form-table-row td:nth-child(2) {
    text-align: left;
    width: 15%;
    white-space: initial;
}

.custom-table.smart-safes .form-table-row th:nth-child(3) {
    text-align: right;
    vertical-align: middle;
    width: 10%;
}

.custom-table.smart-safes .form-table-row td:nth-child(3) {
    text-align: right;
    vertical-align: middle;
    width: 10%;
    white-space: initial;
}

.custom-table.smart-safes .form-table-row th:nth-child(4) {
    width: 8%;
    text-align: right;
    vertical-align: middle;
    white-space: initial;
}

.custom-table.smart-safes .form-table-row td:nth-child(4) {
    width: 8%;
    text-align: right;
    vertical-align: middle;
    white-space: initial;
}

.custom-table.smart-safes .form-table-row th:nth-child(5) {
    width: 7%;
    text-align: right;
    vertical-align: middle;
}

.custom-table.smart-safes .form-table-row td:nth-child(5) {
    width: 7%;
    text-align: right;
    vertical-align: middle;
}

.custom-table.smart-safes .form-table-row th:nth-child(6) {
    text-align: center;
    vertical-align: middle;
    width: 15%;
    white-space: initial;
}

.custom-table.smart-safes .form-table-row td:nth-child(6) {
    text-align: center;
    width: 15%;
    white-space: initial;
}

.custom-table.smart-safes .form-table-row th:last-child {
    width: 5%;
}

.custom-table.smart-safes .form-table-row td:last-child {
    width: 5%;
}

/* SMART SAFE CUSTOM TABLE */

.form-table-row .form-select-div-input {
    width: 76% !important;
}

.form-table-row .form-select-div {
    width: 97%;
    height: inherit;
    background-color: #dee0ea;
}

.custom-table .form-table-row.view-form td {
    padding: 0 8px 0 12px !important;
    background: #ffffff;
}

.custom-table .empty-table-row {
    background: transparent;
    border: none;
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    text-align: center;
    color: #43073d;
}

.table-user-img {
    padding: 0 12px 0 10px;
    width: 62px;
    height: 40px;
    border-radius: 50%;
    /* object-fit: contain; */
}

.table-td-icon {
    padding: 0 12px 0 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    vertical-align: top;
    /* object-fit: contain; */
}

.page-arrow {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin: 0 4px;
    padding: 5px 6px 6px 6px;
    border: none;
    text-decoration: none;
    line-height: 0px;
}

.edit-link .dropdown-menu {
    width: 188px;
    margin-top: 17px;
    margin-left: 116px;
    border: 1px solid #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    padding: 0 0;
    left: 12px !important;
}

.dropdown-menu[x-out-of-boundaries] {
    display: none;
}

.edit-link .dropdown-menu-item {
    display: block;
    width: 100%;
    height: 39px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #43073d;
}

.edit-link .dropdown-menu-item:hover {
    color: #43073d;
    text-decoration: none;
}
.edit-link .dropdown-menu li {
    background-color: #ffffff;
}
.edit-link .dropdown-menu li:hover {
    background-color: #dee0ea;
}

.edit-link .dropdown-menu-item img {
    padding: 8px 13px;
}

.edit-link .dot-icon {
    width: 28px;
    height: 10px;
    padding: 3px 4px;
    background: url(../images/more.png) no-repeat;
}

.edit-link .dot-icon:hover {
    background: url(../images/more-hover.png) no-repeat;
}

.edit-link > a[aria-expanded='true'] .dot-icon {
    background: url(../images/more-hover.png) no-repeat;
}

.page-length-dropdown {
    display: inline;
}
.page-length-dropdown .dropdown-menu {
    width: 36px !important;
    height: 145px !important;
    border-radius: 6px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    min-width: unset;
    padding: 7px 0;
    margin: 0 0 0;
    border: none;
    margin-left: -22px;
}

.page-length-dropdown .dropdown-menu-item {
    width: 36px;
    height: 26px;
}

.page-length-dropdown .dropdown-menu-item:hover {
    background-color: #f3f4f9;
}

.page-length-dropdown .dropdown-menu-item:hover a {
    font-weight: bold;
}
.page-length-dropdown .dropdown-menu-item > a {
    color: #43073d;
    width: 15px;
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    padding: 6px 10px;
}

.download-transaction {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    color: #ee8031 !important;
}

/***************************************************************************************/

.row {
    align-items: baseline;
}

.tab-link-active {
    background-color: inherit !important;
    font-weight: bold !important;
    color: #ee8031 !important;
    border-bottom: 2px solid #ee8031 !important;
}

.data-empty {
    font-family: Poppins;
    font-weight: bold;
    color: #44093e;
    text-align: center;
}

tr:has(> .data-empty) {
    height: auto !important;
}
/*************************************************************
*                   navigation-link-image-styles
**************************************************************/

.dashboard-image-state {
    background: url(../images/dashboard-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .dashboard-image-state {
    background: url(../images/dashboard-hover.png);
}

.sidebar-menu li > a.active .dashboard-image-state {
    background: url(../images/dashboard-active.png);
}

.user-image-state {
    background: url(../images/users-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .user-image-state {
    background: url(../images/users-hover.png);
}

.sidebar-menu li > a.active .user-image-state {
    background: url(../images/users-active.png);
}

.transaction-image-state {
    background: url(../images/menu-icon-transactions.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .transaction-image-state {
    background: url(../images/menu-icon-transactions-hover.png);
}

.sidebar-menu li > a.active .transaction-image-state {
    background: url(../images/menu-icon-transactions-active.png);
}

.settings-image-state {
    background: url(../images/settings-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .settings-image-state {
    background: url(../images/settings-hover.png);
}

.sidebar-menu li > a.active .settings-image-state {
    background: url(../images/settings-active.png);
}

.smart-safe-image-state {
    background: url(../images/menu-icon-smart-safe-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .smart-safe-image-state {
    background: url(../images/menu-icon-smart-safe-hover.png);
}

.sidebar-menu li > a.active .smart-safe-image-state {
    background: url(../images/menu-icon-smart-safe-active.png);
}

.smart-safes-image-state {
    background: url(../images/menu-icon-smart-safes-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .smart-safes-image-state {
    background: url(../images/menu-icon-smart-safes-hover.png);
}

.sidebar-menu li > a.active .smart-safes-image-state {
    background: url(../images/menu-icon-smart-safes-active.png);
}

.agent-image-state {
    background: url(../images/agent-applications-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .agent-image-state {
    background: url(../images/agent-applications-hover.png);
}

.sidebar-menu li > a.active .agent-image-state {
    background: url(../images/agent-applications-active.png);
}

.cashback-settings-image-state {
    background: url(../images/cashback-settings-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .cashback-settings-image-state {
    background: url(../images/cashback-settings-hover.png);
}

.sidebar-menu li > a.active .cashback-settings-image-state {
    background: url(../images/cashback-settings-active.png);
}

.ttb-image-state {
    background: url(../images/settlements-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .ttb-image-state {
    background: url(../images/settlements-hover.png);
}

.sidebar-menu li > a.active .ttb-image-state {
    background: url(../images/settlements-active.png);
}

.admin-ttb-image-state {
    background: url(../images/menu-icon-manage-requests-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .admin-ttb-image-state {
    background: url(../images/menu-icon-manage-requests-hover.png);
}

.sidebar-menu li > a.active .admin-ttb-image-state {
    background: url(../images/menu-icon-manage-requests-active.png);
}


/* .banner-image-state {
    background: url(../images/menu-icon-banner-inactive.png) no-repeat;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.sidebar-menu li > a:hover .banner-image-state {
    background: url(../images/menu-icon-manage-requests-hover.png);
}

.sidebar-menu li > a.active .banner-image-state {
    background: url(../images/menu-icon-banner-active.png);
} */

/**************************************************************************************
*                   Header Component
**************************************************************************************/

.tab-heading {
    font-family: Poppins;
    color: #44093e;
    line-height: 2;
    vertical-align: middle;
}

.tab-heading-title {
    font-size: 36px;
    font-weight: bold;
}

.breadcrumb-separator {
    padding: 0 8px 5px;
}

.breadcrumb-custom, .breadcrumb-custom-last  {
    color: #44093e !important;
    font-size: 18px;
    font-family: Poppins;
    letter-spacing: 0.32px;
}

.breadcrumb-custom-last  {
    color: #ee8031 !important;
}

.breadcrumb-custom:hover,
.breadcrumb-custom:active,
.breadcrumb-custom:focus {
    outline: none;
    text-decoration: underline;
    color: #ee8031 !important;
}

.right-section {
    padding-top: 15px;
    display: flex;
    float: right;
}

.breadcrumb-title {
    font-size: 36px !important;
    font-weight: bold !important;
}

.breadcrumb-link, .breadcrumb-title {
    font-size: 18px;
    font-family: Poppins;
    letter-spacing: 0.32px;
    color: #44093e;
    text-decoration: none;
}

/* .breadcrumb-separator {
    padding: 0px 8px 0px 8px;
} */
/*****************************************************************************
*       Add User Form Section
******************************************************************************/

.add-margin-row {
    margin-top: 12px;
}

.add-section {
    background-color: #ffffff;
    width: 98%;
    padding: 26px 31px 30px;
    border-radius: 6px;
    margin: 32px 0;
}

.add-section.modify-padding {
    padding: 8px 31px 0px;
}

.section-heading {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #44093e;
}

.grv-print-heading {
    font-family: Poppins;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5;
}

.add-section label,
input,
textarea,
small {
    font-family: Lato;
    font-size: 12px;
}

label.form-label {
    letter-spacing: 0.21px;
    color: #9c829d;
    font-family: Lato;
    font-size: 12px;
}

.form-label.required::after {
    color: #d00;
    content: '*';
    margin-left: 3px;
}

label.date-label {
    margin: 0;
}

.form-input::placeholder,
.form-input-half::placeholder {
    letter-spacing: 0.21px;
    font-family: Lato;
    color: #9c829d;
}

.form-select-div {
    width: 97%;
    height: 32px;
    background-color: #f3f4f9;
    border-radius: 8px;
    border: solid 1px #dad4de;
    font-family: Lato;
    font-size: 12px;
    color: #9c829d;
}

.form-select-div-input {
    width: calc(100% - 25px);
    border: none !important;
    padding: 6px 12px 3px !important;
    background-color: inherit;
    letter-spacing: 0.21px;
    color: #43073d !important;
    outline: none;
}

.form-select-dropdown {
    float: right;
    padding: 5px 0;
}

.form-select-dropdown > a {
    padding: 0 12px 0 8px;
}

.form-select-dropdown .dropdown-menu {
    border-radius: 6px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    min-width: 92%;
    padding: 0px 0;
    margin: 0 0 0;
    border: none;
    max-height: 300px;
    overflow-y: scroll;
    transform: none !important;
    left: 3% !important;
    top: 100% !important;
}

.form-select-dropdown .dropdown-menu-item {
    height: 40px;
    padding: 6px 6px;
    color: #43073d;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.21px;
    padding: 6px 10px;
}

.form-select-dropdown .dropdown-menu-item:hover {
    background-color: #f3f4f9;
    font-weight: bold;
}

.form-select-dropdown .dropdown-menu::-webkit-scrollbar {
    width: 3px;
    height: 24px;
}

/* Track */
.form-select-dropdown .dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.form-select-dropdown .dropdown-menu::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

.form-input {
    width: 97%;
    height: 32px;
    background-color: #f3f4f9;
    padding: 8px 12px 9px;
}
.form-input-half {
    width: 46%;
    height: 32px;
    background-color: #f3f4f9;
    padding: 8px 12px 9px;
    margin-right: 16px;
}

.form-input.invalid, .ng-select.invalid .ng-select-container {
    border-color: #dc4c64;
}

.form-select {
    width: 97%;
    height: 32px;
    padding: 8px 12px 6px;
}

.form-input-file {
    width: 89px;
    height: 32px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border: solid 1px #dad4de;
    background-color: #9c829d;
    letter-spacing: 0.21px;
    color: #ffffff;
    padding: 6px 10px;
    cursor: pointer;
}

.form-input-file:hover {
    background-color: #43073d;
    color: #ee8031;
}

.input-file-name {
    font-family: Lato;
    font-size: 12px;
    display: inline-block;
    width: 79%;
    height: 31px;
    border: solid 1px #dad4de;
    background-color: #f3f4f9;
    padding: 6px 12px;
    margin-left: -1px;
    vertical-align: middle;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    color: #9c829d;
}

.form-input-container {
    margin-top: 10px;
}

.add-section textarea {
    height: 64px;
    width: 98.5%;
}

.add-section input,
textarea,
.profile-content input {
    outline: none;
    border-radius: 8px;
    border: solid 1px #dad4de;
    letter-spacing: 0.21px;
    color: #43073d;
}

.add-section input:focus {
    outline: none;
    border-radius: 8px;
    border: solid 1px #ee8031;
    letter-spacing: 0.21px;
    color: #43073d;
}
.add-section textarea:focus {
    outline: none;
    border-radius: 8px;
    border: solid 1px #ee8031;
    letter-spacing: 0.21px;
    color: #43073d;
}

.add-section select {
    outline: none;
    border-radius: 8px;
    border: solid 1px #dad4de;
    letter-spacing: 0.21px;
    font-family: Lato;
    font-size: 12px;
    color: #9c829d;
    background: url(../images/dropdown.png) no-repeat;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-position-x: 98%;
    background-position-y: 50%;
    background-color: #f3f4f9;
}

.add-section select:focus {
    outline: none;
    border-radius: 8px;
    border: solid 1px #ee8031;
    letter-spacing: 0.21px;
    color: #9c829d;
    background: url(../images/dropdown-hover.png) no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    background-color: #f3f4f9;
}

.add-section select:hover {
    outline: none;
    background: url(../images/dropdown-hover.png) no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    background-color: #f3f4f9;
}

.datepicker-input {
    width: 91%;
    background-color: #f3f4f9;
    padding: 8px 12px 9px;
}

.datepicker {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.add-section-footer {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    text-align: center;
    color: #9c829d;
    margin-top: -16px;
}
/*********** Radio button ***********/
.radio-container {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 16px;
}

.radio-container .radio-lable {
    letter-spacing: 0.21px;
    font-size: 14px;
    color: #9c829d;
    font-family: Lato;
    font-weight: bold;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 2px solid #9c829d;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
    background-color: #ffffff;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
    background-color: #ffffff;
    border: 2px solid #43073d;
}

.radio-container input:checked ~ .radio-lable {
    color: #43073d;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #43073d;
}

/*************** time-grid ************************/

.available-time-grid {
    padding: 0px 0px;
    width: 98%;
    display: block;
    border: solid 1px #dad4de;
    background-color: #ffffff;
    border-radius: 8px;
}

.available-time-grid-item {
    width: 50%;
    display: inline-block;
    height: 48px;
    padding: 4px 0px;
    border-bottom: solid 1px #dad4de;
    border-right: solid 1px #dad4de;
}

.time-switch-label {
    padding: 10px 16px;
    float: left;
}

.available-day {
    width: 100px;
    height: 15px;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    color: #9c829d;
    float: left;
    padding: 10px 8px;
}

.time-slot-box {
    width: 128px;
    height: 32px;
    border: solid 1px #dad4de;
    border-radius: 8px;
    display: inline-block;
    background-color: #f3f4f9;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    margin: 4px 12px;
}

.time-slot-box img {
    float: right;
    padding-top: 5px;
}

.time-slot {
    display: inline-block;
    height: 32px;
    width: 82px;
    padding: 6px 14px;
    border-right: solid 1px #dad4de;
    color: #43073d;
}

.time-slot-meridium {
    display: inline-block;
    height: 32px;
    width: 44px;
    padding: 0px 5px;
    color: #ee8031;
}

.time-slot .dropdown-menu {
    width: 68px !important;
    height: 200px !important;
    border-radius: 6px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    min-width: unset;
    padding: 0px 0;
    margin: 0 0 0;
    border: none;
    margin-left: -32px;
    overflow-y: scroll;
}

.time-slot .dropdown-menu::-webkit-scrollbar {
    width: 3px;
    height: 24px;
}

/* Track */
.time-slot .dropdown-menu::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.time-slot .dropdown-menu::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

.time-slot .dropdown-menu-item {
    height: 40px;
    padding: 6px 6px;
}

.time-slot .dropdown-menu-item:hover {
    background-color: #f3f4f9;
}

.time-slot .dropdown-menu-item:hover a {
    font-weight: bold;
}

.time-slot .dropdown-menu-item > a {
    color: #43073d;
    width: 15px;
    height: 15px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.21px;
    padding: 6px 10px;
}

.time-slot-meridium .dropdown-menu {
    width: 50px !important;
    height: 80px !important;
    border-radius: 6px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    min-width: unset;
    padding: 0px 0;
    margin: 0 0 0;
    border: none;
    margin-left: -22px;
}

.time-slot-meridium .dropdown-menu-item {
    height: 40px;
    padding: 6px 6px;
}

.time-slot-meridium .dropdown-menu-item:hover {
    background-color: #f3f4f9;
}

.time-slot-meridium .dropdown-menu-item:hover a {
    font-weight: bold;
}

.time-slot-meridium .dropdown-menu-item > a {
    color: #43073d;
    width: 15px;
    height: 15px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.21px;
    padding: 6px 10px;
}
/**************** custom switch **********************/
.switch-container {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 14px;
    line-height: 12px;
}

.switch-container input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch-container .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: -5px;
    bottom: -3px;
    background-color: #ffffff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.3);
}

.switch-container input:checked + .slider {
    background-color: #f3d6bf;
}

.switch-container input:checked + .slider:before {
    background-color: #f1801d;
}

.switch-container input:focus + .slider {
    box-shadow: 0 0 1px #dad4de;
}

.switch-container input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.switch-container .slider.round {
    border-radius: 34px;
}

.switch-container .slider.round:before {
    border-radius: 50%;
}

/**************************************************************************************
*                   search section
**************************************************************************************/
.search-button {
    width: 227px;
    height: 40px;
    /* padding: 11px 90px; */
    border: none;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    background-color: #62275c;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
}

.search-button.orange {
    background: linear-gradient(90deg, #ee8031 0%, #e45248 100%);
}

.advanced-search {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    color: #672268;
    padding: 0 40px;
    line-height: 3;
}

.advanced-search :hover,
.clear-filters :hover {
    color: #db3069;
}

.advanced-search > u {
    padding: 0 10px;
    white-space: nowrap;
}

.clear-filters {
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.21px;
    color: #672268;
    line-height: 3;
}

.input-icons i {
    position: absolute;
}

.input-icons a {
    float: left;
    position: absolute;
    margin-left: -25px;
    margin-top: 4px;
}

.icon {
    padding: 8px;
    min-width: 40px;
}

.input-icons > input {
    padding-left: 30px;
}

.search-result-header {
    font-family: Poppins;
    font-weight: bold;
    color: #44093e;
    font-size: 20px;
}

.search-form {
    width: 75%;
    display: inline-block;
    margin-right: 2%;
}
.search-form .row {
    margin-bottom: 24px;
}

.searched-items {
    display: inline-block;
    width: 23%;
    vertical-align: top;
    margin-top: 28px;
}

.searched-items > div {
    display: inline-block;
}

.search-item-value {
    display: inherit;
    margin: 2px;
    background-color: #de2b6a;
    padding: 8px 12px;
    color: #ffffff;
    border-radius: 8px;
    font-size: 12px;
    font-weight: bold;
    font-family: Lato;
    letter-spacing: 0.21px;
    width: fit-content;
}

.search-item-value > a {
    padding-left: 10px;
}

/*****************************************************************************
*       Select User Type Section
******************************************************************************/

.user-type-select-component {
    font-family: Lato;
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0.32px;
    color: #43073d;
    margin: 15px -10px;
}

.user-type-dropdown {
    border-radius: 8px;
    background-color: #62275c;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    padding: 9px 8px 10px 16px;
}

.user-type-dropdown > a img {
    float: right;
    padding: 8px 8px;
}

.type-name {
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.27px;
    color: #ffffff;
}

.user-type-dropdown .dropdown-menu {
    border: 1px solid #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    width: 159px;
    min-width: unset;
    padding: 0 0;
    margin: 0 0 0;
    border: none;
    margin-left: -120px;
}

.user-type-dropdown .dropdown-menu-item {
    display: block;
    width: 159px;
    height: 40px;
    line-height: 1.6;
    color: #43073d;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.21px;
    padding: 10px 0 12px 19px;
}

.user-type-dropdown .dropdown-menu-item:hover {
    background-color: #f3f4f9;
}

.user-status-dropdown {
    display: inline-block;
}

.user-status-dropdown .dropdown-menu {
    border: 1px solid #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    width: 100px;
    min-width: unset;
    padding: 0 0;
    margin: 0 0 0;
    border: none;
    margin-left: -62px;
}

.user-status-dropdown .dropdown-menu-item {
    display: block;
    width: 100px;
    height: 40px;
    line-height: 2.6;
}

.user-status-dropdown .dropdown-menu-item:hover {
    background-color: #f3f4f9;
    font-weight: bold;
}

.user-status-dropdown .dropdown-menu-item > a {
    color: #43073d;
    width: 15px;
    height: 15px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.21px;
    padding: 10px 0 12px 19px;
}

/*****************************************************************************
*       Loader
******************************************************************************/
/* The element to apply the animation to */
.custom-loader {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 12px;
    background: #f3f3f3;
    margin: 0 0 0 0;
    position: absolute;
    z-index: 899;
}

.custom-loader-medium {
    width: 100%;
    height: 50vh;
    top: 30%;
    right: 0;
    left: 12px;
    background: #f3f3f3;
    margin: 0 0 0 0;
    position: absolute;
    z-index: 899;
}

.custom-loader-image {
    width: 50px;
    height: 50px;
    background-image: url(../images/logo-icon.png);
    background-repeat: no-repeat;
    animation-name: loading-spinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    position: fixed;
    top: 200px;
    left: 54%;
    z-index: 900;
}

.custom-loader-text {
    width: 150px;
    height: 150px;
    position: absolute;
    top: 250px;
    left: 43.7%;
    color: #ee8031;
    animation-name: loading-spinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    z-index: 900;
}

.custom-loader-text-processing {
    width: 150px;
    height: 150px;
    position: fixed;
    top: 250px;
    left: 52%;
    color: #ee8031;
    animation-name: loading-spinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    z-index: 900;
}

/*****************************************************************************
*       Progess bar
******************************************************************************/

.progress-container > div {
    text-align: center;
}

.progress-container > div > div {
    display: inline-block;
}

.progress-container-text {
    padding: 28px;
    color: #44093e;
    font-size: 20px;
    font-weight: bold;
    font-family: Poppins;
    letter-spacing: 0;
}

.custom-progress-bar {
    height: 20px;
    width: 290px;
    position: relative;
    background: #6b1264;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    border-radius: 20px;
    padding: 3px;
}

.custom-progress-bar > span {
    display: block;
    height: 100%;
    border-radius: 20px;
    background-image: linear-gradient(to right, #ef7f21, #e22c6d);
    position: relative;
    overflow: hidden;
}

/*****************************************************************************
*       edit user
******************************************************************************/
.edit-section-profile {
    background-color: #ffffff;
    width: 62%;
    height: 176px;
    padding: 24px 24px;
    border-radius: 6px;
    margin-right: 16px;
    margin-top: 32px;
    display: inline-block;
    vertical-align: middle;
}

.edit-section-profile label {
    padding-right: 4px;
}

.edit-section-profile .col-md-5,
.edit-section-profile .col-md-7 {
    padding-right: 0px;
}

.profile-placeholder {
    width: 128px;
    height: 128px;
    margin: 2px 6px;
    display: inline-block;
    vertical-align: top;
}

.profile-placeholder img {
    border-radius: 50%;
    width: 128px;
    height: 128px;
}

.profile-content {
    width: calc(100% - 140px);
    display: inline-block;
}

.section-wallet {
    width: 34.5%;
    height: 176px;
    border-radius: 6px;
    margin-top: 32px;
    display: inline-block;
    vertical-align: middle;
}

.profile-content label,
.section-wallet label {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    color: #9c829d;
    white-space: nowrap;
}

.section-wallet .row span {
    margin-top: 36px;
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    color: #43073d;
}

.user-details-text {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    color: #43073d;
    margin-top: -7px;
}

.user-files-view-empty {
    width: 280px;
    height: 40px;
    background-color: #f9dbcf;
    border-radius: 6px;
    font-size: 12px;
    font-family: Lato;
    padding: 11px;
    opacity: 0.3;
}

.user-files-view {
    width: 280px;
    height: 40px;
    background-color: #f9dbcf;
    border-radius: 6px;
    font-size: 12px;
    font-family: Lato;
}

.user-files-view img {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    background-color: #62275c;
    padding: 6px;
}

.user-files-view span {
    padding: 5px;
    color: #43073d;
}

.user-files-view a {
    color: #43073d;
    float: right;
    padding: 11px;
    font-weight: bold;
}

.user-files-deny {
    float: right;
    padding: 10px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    color: #43073d;
}

.details-view .col-md-3,
.details-view .col-md-4,
.details-view .col-md-6 {
    margin-top: 12px;
}

.available-day-view {
    width: 100px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.21px;
    color: #43073d;
}

.available-time-view {
    width: 150px;
    font-family: Lato;
    font-size: 12px;
    color: #43073d;
}

/*****************************************************************************
*       inner tab
******************************************************************************/
.inner-tab {
    overflow: hidden;
}

/* Style the buttons that are used to open the tab content */
.inner-tab-link {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #dad4de;
    font-size: 12px;
    font-family: Lato;
    color: #9c829d;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    height: 30px;
    margin-right: 2px;
}

.inner-tab-link.active {
    background-color: #ffffff;
    color: #43073d;
}

.wallet-tab-content {
    background-color: #ffffff;
    height: 146px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: none;
    padding: 24px;
}

.wallet-tab-content.active {
    display: block;
}

.custom-link-tag {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    color: #43073d;
}

.wallet-tab-content .label-value > div {
    vertical-align: text-top;
}
/*****************************************************************************
*       image model
******************************************************************************/

/* The Modal (background) */
.image-modal {
    position: fixed; /* Stay in place */
    z-index: 1300; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.43); /* Black w/ opacity */
}

.document-window {
    width: 70%;
    height: 90%;
    border-radius: 6px;
    background-color: #ffffff;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 55px 64px;
}

.document-window-img {
    background-color: rgba(0, 0, 0, 0.03);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.document-window-img img {
    max-width: 100%;
    max-height: 100%;
}

/* The Close Button */
.image-close {
    position: absolute;
    top: 20px;
    right: 20px;
}

.deny-window {
    width: 420px;
    height: 330px;
    border-radius: 6px;
    background-color: #ffffff;
    margin: auto;
    padding: 32px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.logout-window {
    width: 405px;
    height: 192px;
    border-radius: 6px;
    background-color: #ffffff;
    margin: auto;
    padding: 32px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.deny-window-close {
    padding: 10px;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    color: #43073d;
    margin: 0 40%;
}

.deny-reason {
    margin-left: 14%;
}

.verify-reason {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 15px;
}

.verify-reason label {
    font-size: 12px;
}

.verify-reason > div > div {
    font-size: 15px;
    margin-bottom: 15px;
}

.user-status-text {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.21px;
    text-align: right;
    color: #9c829d;
    line-height: 37px;
}

.circle {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 11px 5px 0 8px;
    vertical-align: text-bottom;
    float: right;
}

.circle-left {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 11px 5px 0 2px;
    vertical-align: text-bottom;
    float: left;
}
.circle-red {
    background: #ea3334;
}

.circle-black {
    background: #504a5a;
}

.circle-green {
    background: #5bc79c;
}

.circle-orange {
    background: #ee8031;
}

.circle-yello {
    background: #f7cc45;
}

.circle-blue {
    background: #9de4ff;
}

/**************************************************************************************
*                   Html Editor
**************************************************************************************/

.editor-body {
    width: 80%;
    height: 460px;
    border-radius: 6px;
    margin: 16px;
    border: solid 1px #dad4de;
}

.editor-disabled .ck .ck-sticky-panel__content {
    display: none;
}

.editor-body p,
blockquote {
    margin-bottom: 0 !important;
    font-family: Lato !important;
    font-size: 18px !important;
    line-height: 1.33 !important;
    letter-spacing: normal !important;
    color: #333333 !important;
}

.editor-body blockquote {
    font-size: 18.5px !important;
    line-height: 1.41 !important;
    font-style: normal !important;
}

.editor-body h4,
h2,
h3 {
    font-family: Poppins;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #333333;
}

.editor-body h2 {
    font-size: 26px;
}

.editor-body h3 {
    font-size: 24px;
}

.editor-body h4 {
    font-size: 22px;
}

.ck-rounded-corners.ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    height: 410px;
    overflow-y: scroll;
    margin: 8px;
}

.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable) {
    border: none !important;
    box-shadow: none !important;
}

.ck-rounded-corners.ck.ck-editor__main > .ck-editor__editable::-webkit-scrollbar,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.ck-rounded-corners.ck.ck-editor__main > .ck-editor__editable::-webkit-scrollbar-track,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.ck-rounded-corners.ck.ck-editor__main > .ck-editor__editable::-webkit-scrollbar-thumb,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

.ck.ck-toolbar {
    border-radius: 6px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: solid 1px #dad4de !important;
    background-color: #f3f4f9 !important;
}

/**************************************************************************************
*                   status div
**************************************************************************************/

.status-div {
    height: 90%;
    width: fit-content;
    padding: 0 6px;
    background-color: #f3f4f9;
    border-radius: 8px;
    display: inline-block;
    margin: 0 2px;
    color: #62275c;
}

.status-description-search {
    float: right;
    font-weight: normal;
}

.status-description {
    padding: 32px 0 0;
    display: inline-block;
}

.status-description > div {
    margin: 4px 5px 0 2px;
}

.status-description-search > div {
    margin: 8px 5px 0 2px;
}

.status-description-text {
    display: inline-block;
    width: fit-content;
    color: #62275c;
    padding: 0 24px 0 0;
    font-family: Lato;
    font-size: 14px;
}

/**************************************************************************************
*                   Radio Button
**************************************************************************************/

input[type='radio'] {
    transform: scale(1.6);
    accent-color: #000000;
}

/**************************************************************************************
*                   fees table design
**************************************************************************************/

.fees-table {
    width: 100%;
    margin-top: 18px;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.27px;
    color: #43073d;
    border-collapse: collapse;
    border-spacing: 0;
}

.fees-table > tbody {
    /* padding: 12px 0; */
    border-radius: 6px;
    border-top-right-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 0 0 1px #dad4de;
}

.fees-table > thead > tr > th {
    padding: 10px 0px 10px 18px;
    font-size: 12px;
    background-color: #dad4de;
    color: #9c829d;
}

.fees-table > thead > tr > th:first-child {
    width: 32%;
    background-color: transparent;
}

.fees-table > tbody > tr > td:first-child {
    width: 32%;
    border-right: solid 1px #dad4de;
}

.fees-table > thead > tr th:nth-child(2) {
    width: 17%;
    padding-left: 100px;
}

.fees-table > tbody > tr td:nth-child(2) {
    width: 17%;
    padding-left: 100px;
}

.fees-table > thead > tr th:nth-child(3) {
    width: 17%;
    text-align: center !important;
}

.fees-table > tbody > tr td:nth-child(3) {
    width: 17%;
    text-align: center !important;
}

.fees-table > thead > tr th:nth-child(4) {
    width: 17%;
    text-align: center !important;
}

.fees-table > tbody > tr td:nth-child(4) {
    width: 17%;
    text-align: center !important;
}

.fees-table > thead > tr th:last-child {
    width: 17%;
    text-align: center !important;
    padding-right: 100px;
}

.fees-table > tbody > tr td:last-child {
    width: 17%;
    text-align: center !important;
    padding-right: 100px;
}

/*.fees-table > thead > tr th:nth-child(2) {
    border-top-left-radius: 6px;
    box-shadow: 0 0 0 1px #dad4de;
}*/

.fees-table > tbody > tr > td {
    padding: 10px 0px 10px 18px;
}

/*.fees-table > tbody > tr td:first-child {
    border-right: solid 1px #dad4de;
}*/

/*.fees-table > thead > tr th:nth-child(2) {
    border-top-left-radius: 6px;
    box-shadow: 0 0 0 1px #dad4de;
}

.fees-table > thead > tr th:last-child {
    border-top-right-radius: 6px;
    box-shadow: 0 0 0 1px #dad4de;
}

*/
.fees-table > tbody::before {
    height: 12px;
    display: table-row;
    content: '';
}

.fees-table > tbody::after {
    height: 12px;
    display: table-row;
    content: '';
}

.fees-table input[type='text'] {
    width: 100%;
    height: 32px;
    background-color: #f3f4f9;
}

/**************************************************************************************
*                   transaction table design
**************************************************************************************/

.transaction-table {
    margin-top: -25px;
}

.transaction-table table {
    width: 100%;
    border-collapse: separate;
}

.transaction-table tbody {
    display: block;
    height: calc(100vh - 241px);
    overflow: auto;
}

.transaction-table tbody > tr {
    display: table;
    width: calc(100% - 11px);
    table-layout: fixed;
    margin: 10px 11px 10px 0px;
    line-height: 40px;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #504a5a;
    white-space: nowrap;
}

.transaction-table tfoot tr {
    display: table;
    width: calc(100% - 11px);
    table-layout: fixed;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    color: #9c829d;
    padding: 10px 0 10px 0;
}

.transaction-table tfoot tr td span {
    color: #43073d;
    font-weight: bold;
    padding: 0 5px;
}

.transaction-table table tfoot td:last-child {
    text-align: right;
}

.transaction-table tbody::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.transaction-table tbody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.transaction-table tbody::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

/*****************/

.transaction-row {
    background: #ffffff;
    border: 6px solid #ffffff;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.transaction-row > div {
    display: inline-block;
    vertical-align: middle;
}

.transaction-row-element-1 {
    width: 32%;
    text-align: left;
    padding-left: 16px;
}

.transaction-row-element-2 {
    width: 32%;
}

.transaction-row-element-3 {
    width: 30%;
    text-align: right;
}

.transaction-row-element-4 {
    width: 6%;
    text-align: center;
}

.transaction-amount {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    text-align: right;
    color: #ea3334;
    margin-bottom: -23px;
}

.transaction-date {
    font-family: Lato;
    font-size: 10px;
    letter-spacing: 1px;
    text-align: right;
    color: #9c829d;
    margin-bottom: -10px;
}

.transaction-type {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.25px;
    color: #504a5a;
    margin-bottom: -23px;
}

.transaction-status {
    font-family: Lato;
    font-size: 10px;
    letter-spacing: 1px;
    color: #9c829d;
    margin-bottom: -10px;
}

.transaction-status-icon {
    padding: 0 2px 2px 0;
}

.transaction-user-name, .transaction-group-id {
    width: 200px;
    height: 32px;
    border-radius: 8px;
    background-color: #f3f4f9;
    display: inline-block;
    vertical-align: middle;
    margin: 0 16px;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    text-align: center;
    color: #62275c;
    line-height: 32px;
}

.transaction-group-id {
    width: 48px;
}

.transaction-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    background-color: #dad4de;
    border: 0px;
    border-color: #dad4de;
    border-style: solid;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.transaction-details-section {
    padding: 32px 24px 16px;
}

.transaction-details-section label {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    color: #9c829d;
}

.transaction-details-section .header {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.88;
    color: #44093e;
}

.transaction-details-section .col-md-6 {
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    color: #43073d;
}

.label-value {
    font-size: 14px;
}

.label-value > div {
    width: 46px;
    height: 16px;
    display: inline-block;
    border-radius: 6px;
    background-color: #62275c;
    color: #ffffff;
    font-family: Lato;
    font-size: 8px;
    font-weight: bold;
    line-height: 14px;
    letter-spacing: 0.8px;
    text-align: center;
}

.transaction-details-section .label-value {
    margin-top: -30px;
}

.transaction-details-section .label-value > a {
    color: #0a54e7;
}

.transaction-details-section .label-value > div {
    line-height: 15px;
}

.transaction-details-section .row {
    margin-top: -8px;
}

.add-employee-window {
    width: 550px;
    height: 420px;
    border-radius: 6px;
    background-color: #ffffff;
    margin-top: -32px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.add-employee-window .add-section {
    margin: 0 0;
    width: 100%;
}

.add-employee-window .box-outline {
    margin-top: 0;
    width: 230px;
    height: 40px;
}

.submit-icon {
    width: 24px;
    height: 24px;
    padding: 3px 4px;
    background: url(../images/icon-check-mark-normal.png) no-repeat;
}

.submit-icon:hover {
    background: url(../images/icon-check-mark-hover.png) no-repeat;
}

#add-spend-level {
    border-radius: 6px;
    text-align: center;
}

.update-employee-window {
    width: 550px;
    height: 370px;
    border-radius: 6px;
    background-color: #ffffff;
    margin-top: -32px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.update-employee-window .add-section {
    margin: 0 0;
    width: 100%;
}

.update-employee-window .box-outline {
    margin-top: 0;
    width: 230px;
    height: 40px;
}

.update-employee-pin-window {
    width: 330px;
    height: 300px;
    border-radius: 6px;
    background-color: #ffffff;
    margin-top: -32px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.update-employee-pin-window .add-section {
    margin: 0 0;
    width: 100%;
}

.update-employee-pin-window .box-outline {
    margin-top: 0;
    margin-left: 12px;
    width: 150px;
    height: 40px;
}

.refund-employee-window {
    width: 550px;
    height: 300px;
    border-radius: 6px;
    background-color: #ffffff;
    margin-top: -32px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.refund-employee-window .add-section {
    margin: 0 0;
    width: 100%;
}

.refund-employee-window .box-outline {
    margin-top: 0;
    width: 190px;
    height: 40px;
}

.verify-employee-window {
    width: 400px;
    height: 260px;
    border-radius: 6px;
    background-color: #ffffff;
    margin: auto;
    padding: 32px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.verify-employee-window .section-heading {
    text-align: center;
}

.verify-employee-window input {
    font-family: Lato;
    font-size: 12px;
    outline: none;
    border-radius: 8px;
    border: solid 1px #dad4de;
    letter-spacing: 0.21px;
    color: #43073d;
}

.verify-employee-window input:focus {
    outline: none;
    border-radius: 8px;
    border: solid 1px #ee8031;
    letter-spacing: 0.21px;
    color: #43073d;
}

.add-grv-window {
    width: 340px;
    height: 150px;
    border-radius: 6px;
    background-color: #ffffff;
    margin-top: -32px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.add-grv-window .add-section {
    margin: 0 0;
    width: 100%;
}

/************************************************************
* Transfer to Bank
************************************************************/

.ttb-instruction {
    width: 100%;
    height: 111px;
    margin-top: -74px;
    text-align: left;
    padding: 8px 0px 11px 0px;
    border-radius: 8px;
    border: solid 2px #dad4de;
    background-color: #f3f4f9;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.25px;
    color: #43073d;
}

.ttb-instruction hr {
    margin-top: 8px;
    margin-bottom: 8px;
    border: 0;
    border-top: solid 2px #dad4de;
}

.ttb-instruction .vr {
    border-right: solid 2px #dad4de;
    position: absolute;
    top: -59px;
    bottom: 15px;
    left: 48%;
}

.ttb-instruction .pl-4 {
    padding-left: 37px !important;
}

.transaction-details-window {
    width: 500px;
    max-height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0 0 0 83px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    background-color: #dad4de;
}

.transaction-details-header {
    padding: 28px 32px;
}

.transaction-details-header table {
    font-size: 12px;
    line-height: 25px;
    width: 100%;
}

.transaction-details-header table > tbody td:first-child {
    width: 15%;
}

.transaction-details-header table > tbody td:last-child {
    width: 85%;
}

.transaction-details-footer {
    width: 100%;
    height: 80px;
    margin: 5px 0 0;
    padding: 25px 28px 0 40px;
    background-color: #62275c;
    font-size: 20px;
    color: #ffffff;
}

.transaction-details-header .user-details-text {
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #43073d;
}

.transaction-details-header .row {
    margin-bottom: 16px;
}

.transaction-details-header .section-heading {
    margin-bottom: 16px;
}

.transaction-details-header-body {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(120vh - 225px);
}

.transaction-details-header-body::-webkit-scrollbar {
    width: 4px;
    border-radius: 3px;
    background-color: rgba(156, 130, 157, 0.5);
}

/* Track */
.transaction-details-header-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.transaction-details-header-body::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

.transaction-details-window .circle-left {
    margin: 4px 5px 0 2px;
}

.transaction-details-update-window {
    width: 450px;
    height: fit-content;
    padding: 32px 32px 37px;
    border-radius: 6px;
    background-color: #ffffff;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.27px;
    color: #43073d;
}

.transaction-details-update-window textarea {
    width: 97%;
    height: 120px;
    padding: 8px 12px;
    border-radius: 8px;
    border: solid 1px #dad4de;
    background-color: #f3f4f9;
}

.background-gradient-orange {
    background: linear-gradient(to right, #ed7e31, #e45247);
}

.background-transparent {
    background: transparent;
}

.background-inherit {
    background: inherit !important;
}

.text-orange {
    color: #ed7e31 !important;
}

.text-inherit {
    color: inherit !important;
}

.text-heading {
    color: #44093e !important;
}

.text-gradient-primary {
    background: -webkit-linear-gradient(#ee8031, #e45248);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.border-none {
    border: none !important;
}

.transaction-details-custombtn {
    border-radius: 20px;
    border: 2px solid #ed7e31;
    outline: none;
    overflow: hidden;
    width: inherit;
    height: 40px;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
}

.ttb-export-custombtn {
    background-image: linear-gradient(to right, #ed7e31, #e45247);
    border-radius: 20px;
    border: none;
    outline: none;
    overflow: hidden;
    width: 177px;
    height: 40px;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    float: right;
}

.export-ttb {
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #43073d;
}

/************************************************************
* Transfer to Bank
************************************************************/

.add-element-row {
    height: 56px;
    margin: -12px 24px 0px 0px;
    padding: 16px 40% 0 40%;
    border-radius: 6px;
    background-color: #ffffff;
}

.add-element-row:hover {
    background-color: #dee0ea;
}

.add-element-row span {
    padding-left: 6px;
    font-size: 15px;
    font-family: Lato;
    color: #43073d;
    letter-spacing: 0.27px;
}

.transaction-details-log-date {
    line-height: 10px;
}

.use-full-balance-link {
    margin: -20px 16px 0 0;
    float: right;
    color: #ee8031;
}

/**********************************************************************************
*            Dashboard waves balance
**********************************************************************************/
.sales-dashboard,
.waves-system-balance-box {
    height: auto;
    margin: 4px 4px 0 1px;
    padding: 24px 32px 32px 30px;
    border-radius: 6px;
    box-shadow: 0 0 7px 0 rgba(91, 90, 90, 0.09);
    background-color: #ffffff;
}

.waves-balance-box {
    width: 350px;
    height: auto;
    margin: 24px 24px 0 1px;
    padding: 24px 32px 32px 30px;
    border-radius: 6px;
    box-shadow: 0 0 7px 0 rgba(91, 90, 90, 0.09);
    background-color: #ffffff;
}

.dashboard-amount {
    margin: 3px 0px 0 1px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.02px;
}

.grey {
    color: #9c829d;
}

.light-red {
    color: #ee8031;
}

.dark-purple {
    color: #43073d;
}

.green {
    color: #5bc79c;
}

.white {
    color: #ffffff;
}

.open-link-icon {
    width: 46px;
    height: 16px;
    margin: 7px;
    padding: 0 5px;
    border-radius: 6px;
    background-color: #0a54e7;
    font-family: Lato;
    font-size: 8px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.53px;
}

.hr-margin-none {
    margin-top: 0;
    margin-bottom: 0;
}

.width-50 {
    width: 50% !important;
}

.width-33 {
    width: 33.33% !important;
}

.waves-system-balance-box .col-md-6 > div,
.col-md-12 > div {
    margin-top: -8px;
}

.sales-dashboard {
    width: 100%;
}
/**********************************************************************************
*            Hover Overlay
**********************************************************************************/

/* .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    color: #44093e;
    width: 100%;
    transition: .5s ease;
    opacity:0;
    font-size: 12px;
    padding: 8px;
    text-align: center;
  }

.overlay-container:hover .overlay {
    opacity: 1;
} */
/**********************************************************************************
*            Line Height
**********************************************************************************/

.line-height-1 {
    line-height: 1;
}

.line-height-2 {
    line-height: 2;
}

.line-height-3 {
    line-height: 3;
}

.border-orange {
    border-color: #ee8031;
}

.border-white-faded {
    border-color: #dad4de;
}

/**********************************************************************************
*            GRV
**********************************************************************************/
.grv-details-window {
    width: 85%;
    height: 90%;
    border-radius: 6px;
    background-color: #ffffff;
    margin-top: -32px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 32px 48px;
    overflow-y: auto;
    font-size: 16px;
}

.grv-print-window {
    background-color: #ffffff;
    padding: 32px 48px;
    overflow-y: auto;
    font-size: 24px;
    color: #000;
}

.grv-details-window .add-section {
    margin: 0 0;
    width: 100%;
}

.grv-details-window::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.grv-details-window::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.grv-details-window::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

.custom-table.grv tbody {
    height: 40%;
}

.custom-table.grv tbody > tr {
    height: unset;
    line-height: 4px;
}

.grv-details-window .col.data,
.grv-print-window .col.data {
    border: 1px solid #dee0ea;
    padding: 8px 16px;
    border-radius: 1px;
}

.grv-details-window .aggrigate,
.grv-print-window .aggrigate {
    border: 1px solid #dee0ea;
    padding: 8px;
    border-radius: 4px;
    display: contents;
    color: #43073d;
}

.grv-print-window .aggrigate {
    color: #000;
}

.grv-details-window .mat-tab-label {
    color: #43073d;
    font-family: Lato;
    opacity: 1;
    font-size: 16px;
}

.grv-details-window .mat-tab-label.mat-tab-label-active {
    color: #ee8031;
    font-weight: bold;
}

.grv-details-window .mat-tab-group.mat-primary .mat-ink-bar,
.grv-details-window .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #ee8031;
    height: px;
}

/**********************************************************************************
*            BUTTON
**********************************************************************************/

.primary-button {
    background-image: linear-gradient(to right, #ed7e31, #e45247);
    border-radius: 20px;
    border: none;
    outline: none;
    overflow: hidden;
    width: 125px;
    height: 40px;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin: 10px 12px;
}

.secondary-button {
    outline: none;
    overflow: hidden;
    width: 125px;
    height: 40px;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ed7e31;
    border-radius: 20px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    border: solid 1px #ee8031;
    background: inherit !important;
}

button:focus {
    outline: none;
}

.custombtn {
    background-image: linear-gradient(to right, #ed7e31, #e45247);
    border-radius: 20px;
    border: none;
    outline: none;
    overflow: hidden;
    width: 180px;
    height: 35px;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    margin: 10px 12px;
}

.custombtn-cancel {
    outline: none;
    overflow: hidden;
    width: 136px;
    height: 35px;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #0c0c31;
    margin-left: 16px;
    border-radius: 20px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    border: solid 1px #0c0c31;
    background-color: #ffffff;
}

.custombtn:hover {
    color: #121628;
}

.custombtn-cancel:hover {
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    border: solid 1px #0c0c31;
    background-color: #0c0c31;
    color: #ffffff;
}

.custombtn:disabled {
    opacity: 0.5;
}

.custombtn-align-right {
    float: right;
    margin: 0 14px;
}

.btndeactive {
    background-image: linear-gradient(to right, #ed7e31, #e45247);
    border-radius: 20px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    margin: 6px;
    padding: 6px 20px;
    border: none;
    outline: none;
    opacity: 0.5;
}

/**********************************************************************************
*            NG SELECT
**********************************************************************************/

.ng-select .ng-select-container {
    background-color: #f3f4f9 !important;
    border-radius: 8px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #f3f4f9 !important;
    color: #43073d !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #fff !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: #f3f4f9 !important;
    color: #43073d !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: 14px !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked
    .ng-option-label {
    font-weight: bold;
    font-size: 12px !important;
    font-family: Lato;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-family: Lato;
    font-size: 13px;
}

.ng-value-label {
    font-size: 12px;
}

.ng-placeholder {
    color: #43073d !important;
    font-size: 12px;
}

.ng-select .ng-select-container .ng-value-container {
    color: #43073d !important;
    font-size: 14px;
}

/* Scroll Bar*/
.ng-dropdown-panel .scroll-host::-webkit-scrollbar {
    width: 3px;
    height: 24px;
}

/* Track */
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

/**********************************************************************************
*            ngx-toastr
**********************************************************************************/

.toast-message {
    font-family: Lato;
    font-size: 16px;
    color: #877987;
}

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    background-color: white;
    color: #fff;
    width: 300px;
    padding: 15px 30px 15px 30px !important;
    border: 1px solid #eb7883 !important;
}

.toast-success .toast-title {
    font-family: Lato;
    font-size: 16px;
    color: #28a745;
}

.toast-error .toast-title {
    font-family: Lato;
    font-size: 16px;
    color: #eb7883;
}

.toast-success::after {
    display: none;
}

.toast-close-button span {
    color: #000000a1;
}

/******* Input Error *******/

.error-text {
    font-family: Lato;
    font-size: 10px;
    letter-spacing: 1px;
    padding-left: 16px;
    color: #ea3334;
    float: left;
}

.error-text::before {
    margin-left: 16px;
    margin-top: 2px;
    font-size: 6px;
    padding: 0px 1.3px 0px 2.3px;
    border: solid 1px #fd3737;
    content: 'x';
    border-radius: 50%;
    position: absolute;
    vertical-align: middle;
    left: 0;
}

/************************* Errors *****************************************/

.custom-alert-error {
    width: 100%;
    height: 60px;
    background-color: #ea3334;
    position: fixed;
    z-index: 1001;
}

.custom-alert-success {
    width: 100%;
    height: 60px;
    background-color: #5bc79c;
    position: fixed;
    z-index: 1001;
}

.close-modified {
    color: #ffffff !important;
    text-shadow: none !important;
    font-size: 2.1rem !important;
    font-weight: normal !important;
    padding: 0rem 24px !important;
    opacity: 1 !important;
    margin-top: 8px;
    overflow: hidden;
    outline: none;
}

.alert-dismissible .btn-close {
    margin-right: 10px;
}

.login-error-text {
    text-align: center;
    display: block;
}

.login-error-message {
    padding-left: 10px;
    font-family: Lato;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: #ffffff;
    display: inline-block;
}

.error-icon {
    text-align: center;
    display: inline-block;
    vertical-align: text-bottom;
    padding-left: 30px;
}

.errors-list {
    border: solid 1px #fd3737;
    font-size: 12px;
    color: #ea3334;
    padding: 8px 32px 8px 32px;
    width: 100%;
}

/**********************************************************************************
*            SIDE VIEW MODAL
**********************************************************************************/

.sideview-modal-window {
    width: 500px;
    max-height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    margin: 0 0 0 83px;
    box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.1);
    background-color: #dad4de;
    padding: 28px 32px;
}

.add-section {
    background-color: #ffffff;
    width: 98%;
    padding: 26px 31px 30px;
    border-radius: 6px;
    margin: 32px 0;
}

.add-section.modify-padding {
    padding: 8px 31px 0px;
}

.section-heading {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #44093e;
}

.add-section .col-md-6 {
    margin-top: 12px;
}

.add-section .col-md-3 {
    margin-top: 12px;
}

.add-section .col-md-9 {
    margin-top: 12px;
}

.add-section .col-md-10 {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.sideview-modal-body textarea {
    height: 64px;
    width: 98.5%;
}

.sideview-modal-body input,
textarea {
    outline: none;
    border-radius: 8px;
    border: solid 1px #dad4de;
    letter-spacing: 0.21px;
    color: #43073d;
}

.sideview-modal-body input:focus,
textarea:focus {
    border: solid 1px #ee8031;
}

.sideview-modal-body select {
    outline: none;
    border-radius: 8px;
    border: solid 1px #dad4de;
    letter-spacing: 0.21px;
    font-family: Lato;
    font-size: 12px;
    color: #9c829d;
    background: url(../images/dropdown.png) no-repeat;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-position-x: 98%;
    background-position-y: 50%;
    background-color: #f3f4f9;
}

.sideview-modal-body select:focus {
    outline: none;
    border-radius: 8px;
    border: solid 1px #ee8031;
    letter-spacing: 0.21px;
    color: #9c829d;
    background: url(../images/dropdown-hover.png) no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    background-color: #f3f4f9;
}

.sideview-modal-body select:hover {
    outline: none;
    background: url(../images/dropdown-hover.png) no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    background-color: #f3f4f9;
}

.sideview-modal-body table {
    font-size: 12px;
    line-height: 25px;
    width: 100%;
}

.sideview-modal-body table > tbody td img {
    padding: 4px 0px 4px 1px;
    width: 51px;
    height: 49px;
    border-radius: 11%;
    border: 1px solid #d2c3d2;
    margin-right: 13px;
}

.sideview-modal-footer {
    width: 100%;
    height: 80px;
    margin: 5px 0 0;
    padding: 25px 28px 0 40px;
    background-color: #62275c;
    font-size: 20px;
    color: #ffffff;
}

.sideview-modal-body .user-details-text {
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #43073d;
}

.sideview-modal-body .row {
    margin-bottom: 16px;
}

.sideview-modal-header {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #44093e;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
}

.sideview-modal-body-content {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(120vh - 225px);
}

.sideview-modal-body-content::-webkit-scrollbar {
    width: 4px;
    border-radius: 3px;
    background-color: rgba(156, 130, 157, 0.5);
}

/* Track */
.sideview-modal-body-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 3px;
}

/* Handle */
.sideview-modal-body-content::-webkit-scrollbar-thumb {
    background: #dad4de;
    border-radius: 10px;
}

.sideview-modal-window .circle-left {
    margin: 4px 5px 0 2px;
}

.sideview-modal-update-window {
    width: 335px;
    height: fit-content;
    padding: 32px 32px 37px;
    border-radius: 6px;
    background-color: #ffffff;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.27px;
    color: #43073d;
}

.sideview-modal-update-window textarea {
    width: 261px;
    height: 120px;
    padding: 8px 12px;
    border-radius: 8px;
    border: solid 1px #dad4de;
    background-color: #f3f4f9;
}

.sideview-modal-custombtn {
    border-radius: 20px;
    border: 2px solid #ed7e31;
    outline: none;
    overflow: hidden;
    width: inherit;
    height: 40px;
    font-family: Lato;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
}

/**********************************************************************************
*            PRODUCT ORDER
**********************************************************************************/

.product-order-status {
    height: auto !important;
}

#productOrderFulfillModal .modal-dialog {
    width: auto !important;
}

/**********************************************************************************
*            SALES/BUY DASHBOARD
**********************************************************************************/
.dashboard-card-heading {
    font-family: Poppins;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: #44093e;
}

.custom-tooltip-inner {
    width: 700px;
}

/**********************************************************************************
*            FIELDSET/LEGEND
**********************************************************************************/
fieldset {
    border: 1px groove #dddd !important;
    padding: 1.4em !important;
    margin-bottom: 12px;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
}

legend {
    font-family: Poppins;
    font-size: 1.25em !important;
    font-weight: bold;
    line-height: 1.5;
    color: #44093e;
    width: auto;
}

/**********************************************************************************
*            MODAL STYLE
**********************************************************************************/
/* The Modal (background) */
/* .image-modal {
    position: fixed; Stay in place
    z-index: 1300; Sit on top
    left: 0;
    top: 0;
    width: 100%; Full width
    height: 100%; Full height
    overflow: auto; Enable scroll if needed
    background-color: rgba(0, 0, 0, 0.43);  Black w/ opacity
} */

.modal-container {
    z-index: 1300; /* Sit on top */
    background-color: rgba(0, 0, 0, 0.43); /* Black w/ opacity */
}

.modal-content-custom {
    padding: 2rem 2rem;
}

.modal-header-custom {
    padding: 0 0 0.5rem;
    margin-bottom: 27px;
}

.modal-title-custom {
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #44093e;
}

.modal-content-custom textarea {
    width: 97%;
    height: 120px;
    padding: 8px 12px;
    border-radius: 8px;
    border: solid 1px #dad4de;
    background-color: #f3f4f9;
}

.modal-footer-custom {
    margin-top: 27px;
}

